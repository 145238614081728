<template>
  <van-pull-refresh
    style="min-height: 100%"
    v-model="isRefreshing"
    @refresh="onRefresh"
  >
    <div class="class-content" v-scroll:#read-scroll="onScroll">
      <v-fade-transition>
        <div v-if="!isEmpty(this.classList)">
          <div class="head">
            <span>DAILY<br />READING</span>
            <span class="sub-head">每日精选外刊</span>
          </div>
          <div class="first-class">
            <v-card
              class="mx-auto rounded-lg"
              max-width="800"
              :style="`background-color: ${classList[0].theme.bgColor}`"
            >
              <v-img
                height="250px"
                :src="classList[0].img"
                :style="`color: ${classList[0].theme.lightTextColor}`"
                :gradient="`to bottom, #00000000, ${classList[0].theme.bgColor}33, ${classList[0].theme.bgColor}dd, ${classList[0].theme.bgColor}ff`"
              >
                <div class="cover">
                  <v-card-subtitle
                    class="pt-0 time d-block"
                    style="font-size: 0.8rem"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        style="line-height: 1"
                        small
                        :color="classList[0].theme.lightTextColor"
                      >
                        mdi-clock
                      </v-icon>
                      <span class="ml-1">{{ classList[0].time }}</span>
                    </div>
                    <div class="d-flex align-center mt-1">
                      <v-icon
                        style="line-height: 1"
                        small
                        :color="classList[0].theme.lightTextColor"
                      >
                        mdi-tag
                      </v-icon>
                      <span class="ml-1">{{ classList[0].tag }}</span>
                    </div>
                  </v-card-subtitle>
                  <v-card-title style="font-size: 1.2rem">
                    {{ classList[0].title }}
                  </v-card-title>
                </div>
                <template v-slot:placeholder>
                  <v-row
                    :style="`background-color:${classList[0].theme.bgColor}dd`"
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                :style="`background-color:${classList[0].theme.bgColor};color: ${classList[0].theme.lightTextColor}aa`"
              >
                <v-card-text class="text--primary">
                  <div :style="`color: ${classList[0].theme.lightTextColor}`">
                    {{ classList[0].subTitle }}
                  </div>
                </v-card-text>

                <v-card-actions class="d-flex justify-space-between pb-5 px-4">
                  <div class="d-flex left-mark">
                    <div>
                      已学习：{{ classList[0].learnCount
                      }}<span style="font-size: 0.8rem">（人）</span>
                    </div>
                    <div class="d-flex">
                      <div>难度：</div>
                      <v-rating
                        background-color="grey lighten-2"
                        color="warning"
                        hover
                        length="5"
                        readonly
                        x-small
                        size="1"
                        dense
                        :value="classList[0].rate"
                      ></v-rating>
                    </div>
                  </div>
                  <v-btn
                    dense
                    class="px-5"
                    rounded
                    :style="`color: ${classList[0].theme.darkTextColor}`"
                    @click="goPassagePage(classList[0].id)"
                  >
                    阅读文章
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </div>
          <sticky
            class="my-5"
            style="width: 100%; break-inside: avoid"
            :offset-top="isApp ? systemBarHeight + 48 : 48"
            @change="stickyChange"
          >
            <tool-bar :isSticky="isSticky"></tool-bar>
          </sticky>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              lg="4"
              class="other-class mb-7"
              v-for="(item, index) in classList.slice(1, classList.length)"
              :key="item.id"
            >
              <v-lazy
                v-model="item.isActive"
                :options="{
                  threshold: 0.5,
                }"
                min-height="200"
                transition="fade-transition"
              >
                <div>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center mark left-mark">
                      <v-icon
                        style="line-height: 1"
                        small
                        :color="item.theme.lightTextColor"
                      >
                        mdi-clock
                      </v-icon>
                      <span class="ml-1">{{ item.time }}</span>
                    </div>
                    <div class="d-flex align-center mt-1 mark right-mark">
                      <v-icon
                        style="line-height: 1"
                        small
                        :color="item.theme.lightTextColor"
                      >
                        mdi-tag
                      </v-icon>
                      <span class="ml-1">{{ item.tag }}</span>
                    </div>
                  </div>
                  <v-card
                    @click="goPassagePage(item.id)"
                    v-ripple
                    class="rounded-lg"
                    elevation="0"
                    :style="`background-image: linear-gradient(to right top,${item.theme.bgColor}00,${item.theme.bgColor}00,${item.theme.bgColor}09,${item.theme.bgColor}66);`"
                  >
                    <div
                      class="d-flex flex-no-wrap justify-space-between align-center"
                    >
                      <div
                        class="ml-4 mr-1"
                        style="max-height: 4rem; overflow: hidden"
                      >
                        <div class="card-title" v-text="item.title"></div>
                      </div>
                      <v-avatar class="ma-3 rounded" size="70" tile>
                        <v-img :src="item.img">
                          <template v-slot:placeholder>
                            <v-row
                              :style="`background-color:${item.theme.bgColor}dd`"
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                    </div>
                    <div
                      class="d-flex justify-space-between align-center"
                      :style="`color: ${classList[0].theme.darkTextColor}aa;`"
                    >
                      <v-card-subtitle
                        class="mt-0 pt-0 pb-0 cut-words"
                        :style="`color: ${classList[0].theme.darkTextColor}aa`"
                        >{{ item.subTitle }}
                        <div class="d-flex align-center pr-3 left-desc">
                          <div class="d-flex align-center">
                            <span>难度</span>
                            <v-rating
                              class="rate"
                              background-color="grey lighten-2"
                              color="warning"
                              hover
                              length="5"
                              readonly
                              x-small
                              size="1"
                              dense
                              :value="classList[0].rate"
                            ></v-rating>
                          </div>
                          <div class="mx-1">/</div>
                          <div>{{ classList[0].learnCount }} 人已学</div>
                        </div></v-card-subtitle
                      >
                      <v-btn
                        :style="`color: ${classList[0].theme.darkTextColor}`"
                        class="mr-1 btn-disabled"
                        x-small
                        dense
                        text
                      >
                        <v-icon small dense>mdi-play</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                  <v-divider
                    v-show="index < classList.length - 2"
                    style="opacity: 0.5"
                    class="mb-1 mt-9"
                  ></v-divider>
                </div>
              </v-lazy>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
      <error-page
        :offLine="offLine"
        @doRefresh="onRefresh"
        v-if="!isLoading && isEmpty(this.classList)"
      ></error-page>
      <app-load-more
        class="mt-5"
        v-show="!isEmpty(this.classList)"
        :isLoading="isLoading"
        :hasMore="hasNext"
        @loadMore="loadData(true)"
      ></app-load-more>
      <div v-show="!isEmpty(this.classList)" class="footer-takeplace"></div>
    </div>
  </van-pull-refresh>
</template>

<script>
import { Sticky } from "vant";
import $api from "@/api/api";
import AppLoadMore from "@/components/app/appLoadMore";
import { isEmpty } from "@/utils/common";
import { buttonVibrate } from "@/mixin/mixin";

import { mapGetters } from "vuex";

export default {
  name: "readText",
  mixins: [buttonVibrate],
  components: {
    Sticky: Sticky,
    AppLoadMore,
    ErrorPage: () => import("@/views/read/components/errorPage"),
    ToolBar: () => import("@/views/read/components/toolBar"),
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      systemBarHeight: "systemBarHeight",
    }),
  },
  data: () => ({
    offLine: false,
    isLoading: true,
    isRefreshing: false,
    firstCreat: true,
    lastScrollTop: 0,
    totalPages: 0,
    hasNext: false,
    isLoadingMore: false,
    currentPage: 1,
    isSticky: false,
    classDetail: {},
    classList: [],
  }),
  methods: {
    isEmpty,
    stickyChange(val) {
      this.isSticky = val;
    },
    goPassagePage(id) {
      this.zzzShort();
      this.$router
        .push({
          path: "/reader",
          query: {
            id: id,
          },
        })
        .catch((err) => err);
    },
    //页面滚动判断方法
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        //scrollTop被卷去的高度 ；offsetHeight可视区域高度；scrollHeight整个元素的总高度包括不可见区域
        let scrollHeight = e.target.scrollHeight;
        let scrollTop = e.target.scrollTop;
        let offsetHeight = e.target.offsetHeight;
        if (
          scrollHeight - offsetHeight - scrollTop < 150 &&
          !this.isLoading &&
          !this.isLoadingMore
        ) {
          console.log("loadmore...");
          if (this.hasNext) {
            this.isLoadingMore = true;
            this.loadData(true);
          }
        }
      }, 50);
    },
    onRefresh() {
      // 调接口
      this.loadData();
    },
    loadData(loadMore) {
      this.offLine = false;
      this.isLoading = true;
      if (loadMore) {
        if (!this.hasNext) {
          this.isLoading = false;
          return this.$toast("已经加载到最后啦!");
        }
      } else {
        // 全新加载
        this.classList = [];
        this.currentPage = 1;
        this.isLoadingMore = false;
        this.totalPages = 0;
        this.hasNext = false;
      }
      let getData = {
        id: 1,
        limit: 20,
        page: this.currentPage,
      };
      $api
        .getPassageList(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.currentPage += 1;
            let result = res.data.result;
            this.classList = this.classList.concat(result);
            this.totalPages = res.data.totalPages;
            this.hasNext = res.data.hasNext;
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          this.offLine = true;
          console.log(err);
        })
        .finally(() => {
          this.$forceUpdate();
          this.isLoading = false;
          this.isLoadingMore = false;
          this.isRefreshing = false;
        });
    },
    init() {},
  },
  created() {
    console.info("classList组件被创建");
    this.init();
    this.loadData();
  },
  mounted() {
    console.info("classList组件被挂载");
  },
  activated() {
    console.info("classList组件被激活");
    if (this.firstCreat) {
      // 第一次创建
      this.firstCreat = false;
    } else {
      this.$emit("goHistoryScroll", this.lastScrollTop);
      this.init();
    }
  },
  deactivated() {
    this.lastScrollTop = document.querySelector("#read-scroll").scrollTop;
    console.info("classList组件被暂停");
  },
  watch: {
    isLoading() {
      this.$emit("changeLoading", this.isLoading);
    },
  },
};
</script>

<style lang="scss" scoped>
//mobile

.class-content {
  width: calc(100% - 1rem);
  margin: 0 auto;
  min-height: 82vh;
  padding: 0 0;
  word-wrap: break-word;

  @media (min-width: 960px) {
    width: 70%;
    margin: 0 auto; // 剧中
    max-width: 900px !important;
  }
  @media (min-width: 1264px) {
    max-width: 1085px !important;
  }
  @media (min-width: 1904px) {
    max-width: 1185px !important;
  }
  .first-class,
  .other-class,
  .head {
    padding: 0 0.9rem;
    break-inside: avoid;
  }

  .head {
    font-size: 1.2rem;
    margin: 19px 0;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .sub-head {
      display: block;
      font-size: 0.8rem;
      color: #a9a9a9;
      font-weight: 300;
    }
  }
  .cover {
    width: 100%;
    height: 100%;
    padding-top: 1.1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    .time {
      font-size: 0.9rem;
      vertical-align: middle;
      display: flex;
      align-items: center;
    }
  }
  .first-class {
    .left-mark {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      transform-origin: left;
      flex-direction: column;
      font-size: 1rem;
    }
  }
  .other-class {
    .rate {
      --webkit-transform: scale(0.9);
      transform: scale(0.9);
      transform-origin: top;
    }
    .card-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 1rem;
      line-height: 1.3;
      font-weight: 700;
    }
    .left-desc {
      font-size: 0.9rem;
      vertical-align: middle;
      -webkit-transform: scale(0.85);
      transform: scale(0.85);
      transform-origin: left;
    }
    .mark {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    .left-mark {
      transform-origin: left;
    }
    .right-mark {
      transform-origin: right;
    }
  }

  .footer-takeplace {
    width: 100%;
    height: 3.5rem;
  }
}
</style>
